import { useRef, useEffect, useState } from "react";
import "./style.scss";

export default function SelectWithSearch({
    label,
    search,
    searchHandler,
    options,
    optionsHandler,
    focusHandler,
    blurHandler,
    required,
    disabled,
    value,
}) {
    const searchRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !searchRef.current?.contains(target)) {
                setIsOpen(false);
            }
        });
    }, []);

    const searchInputChangeHandler = (e) => {
        searchHandler(e);
    };

    const searchInputFocusHandler = () => {
        setIsOpen(true);
        focusHandler();
    };

    const searchInputBlurHandler = () => {
        if (!blurHandler) return;
        blurHandler();
    };

    return (
        <>
            <div className='custom-search-input' ref={searchRef}>
                <div className='custom-search-input-label'>
                    {label}
                    {required && <span className='custom-search-input-label-required'> *</span>}
                </div>
                <input
                    type='text'
                    placeholder={value}
                    disabled={disabled}
                    value={search}
                    onChange={(e) => searchInputChangeHandler(e)}
                    onFocus={searchInputFocusHandler}
                    onBlur={searchInputBlurHandler}
                    data-disabled={disabled ? "true" : "false"}
                />
                <div className='custom-search-input-options' hidden={isOpen ? false : true}>
                    {options.map((i, index) => {
                        return (
                            <div key={index} onClick={() => setIsOpen(false) || optionsHandler(i)}>
                                {i.value}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
