import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// api
import axios from "axios";
import API from "../../app/api";
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";

// components
import Aside from "../../components/Aside/Aside";
import PageTitle from "../../components/PageTitle/PageTitle";
import InputDate from "../../components/UI/admin/InputDate/InputDate";
import Select from "../../components/UI/admin/Select/Select";
import ApplicationsTable from "../../components/nush-applications/ApplicationsTable/ApplicationsTable";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/UI/Button/Button";

// styles
import "./style.scss";

// constants
const PAGE_TITLE = "Реєстрації на курси НУШ (UNESCO)";
const LIMIT_OPTIONS = [10, 20, 50, 100, 500];
const ORDER_OPTIONS = { "Номером учасника": "id", Курсом: "course", "Іменем учасника": "first_name", "Прізвищем учасника": "last_name" };

const NushApplicationsPage = () => {
    const navigate = useNavigate();
    const containerRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    const [limit, setLimit] = useState(10);
    const [order, setOrder] = useState("id");
    const [orderTitle, setOrderTitle] = useState("Номером учасника");
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    const [applications, setApplications] = useState([]);
    const [applicationsAmount, setApplicationsAmount] = useState(null);

    useEffect(() => {
        axios
            .post(API.application.nush.readAllAmount, { startDate, endDate }, { headers: authHeader() })
            .then((response) => {
                if (response.data) {
                    (response.data.amount || response.data.amount === 0) &&
                        (setApplicationsAmount(response.data.amount) || setPages(Math.ceil(response.data.amount / limit)));
                }
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    }, [limit, startDate, endDate, navigate]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(API.application.nush.readAll, { limit: limit, offset: (page - 1) * limit, order, startDate, endDate }, { headers: authHeader() })
            .then((response) => {
                if (response.data) {
                    setApplications(response.data);
                }
                console.log(response.data);

                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    }, [limit, page, order, startDate, endDate, navigate]);

    const orderHandler = (value) => {
        setOrderTitle(value);
        setOrder(ORDER_OPTIONS[value]);
    };

    const limitHandler = (limit) => {
        setPage(1);
        setLimit(limit);
    };

    const updatePage = (value) => {
        containerRef.current.scrollIntoView();
        setPage(value);
    };

    const downloadApplications = () => {
        axios
            .get(API.application.nush.readAllDownload, { headers: authHeader(), responseType: "blob" })
            .then((response) => {
                const fileName = "Реєстрації на курси НУШ (UNESCO).csv";
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement("a");

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>
            <div className='main-wrapper'>
                <Aside />

                <main className='container' ref={containerRef}>
                    <div className='nush-applications'>
                        <PageTitle pageTitle={PAGE_TITLE} />

                        <div className='unicef-applications-additional-control'>
                            <Link to={"/courses/nush"} target='_blank'>
                                Переглянути форму
                            </Link>
                            <button onClick={downloadApplications}>Експортувати всі дані</button>
                        </div>

                        <div className='nush-applications-filter'>
                            <div>
                                <InputDate label='Від' value={startDate} handler={setStartDate} />
                                <InputDate label='До' value={endDate} handler={setEndDate} />
                            </div>
                        </div>
                        <Select
                            label='Сортувати результати за'
                            value={orderTitle}
                            undefinedOption={false}
                            options={Object.keys(ORDER_OPTIONS)}
                            handler={orderHandler}
                        />

                        <div className='nush-applications-limit'>
                            <div>
                                Знайдено записів: <span>{applicationsAmount}</span>
                            </div>
                            <div>
                                <p>Кількість записів на сторінці:</p>

                                {LIMIT_OPTIONS.map((limitOption, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            content={limitOption}
                                            handler={() => {
                                                limitHandler(limitOption);
                                            }}
                                            disabled={limit === limitOption}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                        <div>
                            <ApplicationsTable applications={applications} deleteApplicationHandler={() => {}} />
                        </div>

                        <Pagination page={page} pages={Array.from(Array(pages).keys())} handler={updatePage} />
                    </div>
                </main>
            </div>
        </>
    );
};

export default NushApplicationsPage;
