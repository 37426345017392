import "./style.scss";

export default function CustomCheckBox({ label, value, handler, required = false }) {
    return (
        <div className='custom-checkbox'>
            <label className='custom-checkbox-label'>
                <input className='custom-checkbox-input' type='checkbox' checked={value} onChange={handler} />
                <span className='checkmark'></span>
                <div>
                    {label} {required && <span className='custom-checkbox-required'> *</span>}
                </div>
            </label>
        </div>
    );
}
